import React from "react";
import SectionTitle from "@/components/section-title";
import { ContactFromData, ContactInfosData } from "@/data";
import { Col, Row } from "react-bootstrap";

const ContactOne = () => {
  return (
    <section className="contact-section sec-pad">
      <div className="thm-container">
        <Row>
          <Col lg={8}>
            <div className="contact-form-content">
              <SectionTitle data={ContactFromData.sectionContent} />

              <form action="https://getform.io/f/f5348353-c39f-40b4-88fd-eb080c45d7a8" className="contact-form" method="POST">
                <input type="text" name="name" placeholder="Nombre completo" />
                <input
                  type="text"
                  name="email"
                  placeholder="Correo electrónico"
                />
                <textarea
                  name="message"
                  placeholder="Mensaje"
                ></textarea>
                <button type="submit" className="thm-btn yellow-bg">
                  Enviar
                </button>
                <div className="form-result"></div>
              </form>
            </div>
          </Col>
          <Col lg={4}>
            <div className="contact-info text-center">
              <SectionTitle data={ContactInfosData.sectionContent} />
              {ContactInfosData.posts.map(({ title, text, socials }, index) => {
                return (
                  <div className="single-contact-info" key={index}>
                    <h4>{title}</h4>
                    {text !== undefined ? <p>{text}</p> : null}
                  </div>
                );
              })}
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default ContactOne;
